import React from "react"
import HeroSlides from "../components/hero-slides"
import Layout from "../components/layout"
import HomeStats from "../components/home-stats"
import HomeSolutions from "../components/home-solutions"
import HomeAbout from "../components/home-about"
import Footer from "../components/footer"
import HomeVideo from "../components/home-video"
import HomeWorkingProcess from "../components/home-working-process"
import HomeRecentProjects from "../components/home-recent-projects"
import HomeClientTestimonials from "../components/home-client-testimonials"
import Technologies from "../components/technologies"
import Testimonials from "../components/home-client"
import Hometech from "../components/home-tech"

import SocialIconsFooter from "../components/social-icons-footer"
import HomeCdn from "../components/home-cdn"

const IndexPage = () => {
    return (
        <Layout
            pageTitle="PragICTS | Cyber Security Operations (CyberSecOps) Services Company In Sri Lanka"
        >
            <div id="wrapper">
                <div className="scroll-nav-wrap fl-wrap">
                    <div className="scroll-down-wrap">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                        <span>Scroll Down</span>
                    </div>
                    <nav className="scroll-nav scroll-init">
                        <ul>
                            {/* <li><a className="scroll-link act-link" href="#sec1">Hero</a></li> */}
                            <li><a className="scroll-link" href="#overview">Overview</a></li>
                            <li><a className="scroll-link" href="#KPIs">KPIs</a></li>
                            <li><a className="scroll-link" href="#services">Services</a></li>
                         
                            <li><a className="scroll-link" href="#technologies">technologies</a></li>
                      
                         

                          
                 
                        </ul>
                    </nav>
                </div>

                <HeroSlides />

                <div className="content">

                    {/* overview section */}
                    <HomeAbout />

                    {/* solutions  */}
                  <HomeSolutions />

                    {/* numbers section */}
                    {/* <HomeCdn /> */}
                    <HomeStats />

     {/* client testimonials */}
     <HomeClientTestimonials />

              {/* featured projects */}
              <HomeRecentProjects />



{/* client testimonials
<Technologies /> */}

{/* video presentation */}
{/* <HomeVideo /> */}



{/* client testimonials */}
<Hometech />



             
              

                


                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default IndexPage
